<template>
  <b-modal
    id="modal-menu-create"
    centered
    scrollable
    body-class="max-h-500px"
  >
    <template #modal-header="{ close }">
      <div class="flex w-full justify-between items-center py-1">
        <h3 class="mb-0">{{ isEdit ? 'Edit Menu' : 'Add Menu' }}</h3>
        <feather-icon
          class="cursor-pointer"
          icon="XIcon"
          size="20"
          @click="close"
        />
      </div>
    </template>

    <validation-observer ref="form">
      <b-form>
        <b-row>
          <b-col sm="12">
            <b-row>
              <b-col sm="12">
                <b-tabs pills nav-class="tab-grow">
                  <b-tab title="English" active>
                    <b-form-group>
                      <label for="menu-name">Menu Name <span class="text-red">*</span></label>
                      <validation-provider
                        #default="{ errors }"
                        name="menu name"
                        rules="required"
                      >
                        <b-form-input
                          v-model="values.english.menuName"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Menu Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group>
                      <label for="menu-description">Description</label>
                      <b-form-textarea
                        v-model="values.english.menuDescription"
                        rows="5"
                        placeholder="Description"
                        :maxlength="63"
                      />
                      <small>{{ values.english.menuDescription.length }}/63</small>
                    </b-form-group>
                  </b-tab>
                  <b-tab title="Thai">
                    <b-form-group>
                      <label for="menu-name">Menu Name</label>
                      <b-form-input
                        v-model="values.thai.menuName"
                        placeholder="Menu Name"
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="menu-description">Description</label>
                      <b-form-textarea
                        v-model="values.thai.menuDescription"
                        rows="5"
                        placeholder="Description"
                        :maxlength="63"
                      />
                      <small>{{ values.thai.menuDescription.length }}/63</small>
                    </b-form-group>
                  </b-tab>
                  <b-tab title="Chinese">
                    <b-form-group>
                      <label for="menu-name">Menu Name</label>
                      <b-form-input
                        v-model="values.chinese.menuName"
                        placeholder="Menu Name"
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="menu-description">Description</label>
                      <b-form-textarea
                        v-model="values.chinese.menuDescription"
                        rows="5"
                        placeholder="Description"
                        :maxlength="63"
                      />
                      <small>{{ values.chinese.menuDescription.length }}/63</small>
                    </b-form-group>
                  </b-tab>
                </b-tabs>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="12">
            <b-form-group>
              <label for="category">Category <span class="text-red">*</span></label>
              <validation-provider
                #default="{ errors }"
                name="category"
                rules="required"
              >
                <v-select
                  v-model="values.category"
                  :state="errors.length > 0 ? false:null"
                  :options="[
                    ...categoryOptions,
                    ...form.menu.category.map(item => ({ label: item.english.categoryName, value: item.english.categoryName }))
                  ]"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="category"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="12">
            <div 
              class="flex flex-col preview-image dashed h-150px"
              :hidden="values.imageUrl !== ''"
              id="dropzone-modal"
              :class="loading ? 'pointer-none' : ''"
            >
              <div
                class="flex flex-col items-center"
                @click="$refs.file.$refs.input.click()"
              >
                <feather-icon
                  icon="UploadCloudIcon"
                  size="30"
                />
                <span class="font-18px">Size dimension 800x800 px</span>
                <span>(Size not exceed 700 KB - PNG, JPG)</span>
              </div>
            </div>
            <b-img
              :src="values.imageUrl"
              :hidden="values.imageUrl === ''"
              class="image-full cursor-pointer"
              @click="$refs.file.$refs.input.click()"
            />
          </b-col>
          <b-col sm="12" class="mt-12px">
            <b-form-group>
              <label for="image">Menu Image <span class="text-red">*</span></label>
              <validation-provider
                #default="{ errors }"
                ref="image"
                name="menu image"
                rules="required"
              >
                <b-form-file
                  ref="file"
                  :disabled="loading"
                  v-model="values.image"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Choose another file"
                  accept=".jpg, .png"
                  @input="handleUploadImage"
                  :class="loading ? 'loading-form-file': ''"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <h4>Setting</h4>
          </b-col>
          <b-col sm="12">
            <b-form-group>
              <label for="price">Price (THB) <span class="text-red">*</span></label>
              <validation-provider
                #default="{ errors }"
                name="price"
                rules="required"
              >
                <b-form-input
                  v-model="values.price"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Price"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group>
              <label for="recommended">Recommended <span class="text-red">*</span></label>
              <b-form-radio-group v-model="values.recommended">
                <b-form-radio value="Yes">
                  Yes
                </b-form-radio>
                <b-form-radio value="No">
                  No
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group>
              <label for="status">Status <span class="text-red">*</span></label>
              <validation-provider
                #default="{ errors }"
                name="status"
                rules="required"
              >
                <v-select
                  v-model="values.status"
                  :state="errors.length > 0 ? false:null"
                  :options="statusOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="status"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <template #modal-footer="{ cancel, ok }">
      <div class="flex w-full">
        <b-button
          class="flex-grow"
          variant="outline-secondary"
          @click="cancel"
        >
          <span>Cancel</span>
        </b-button>
        <b-button
          class="flex-grow ml-1"
          variant="success"
          type="submit"
          @click="validationForm(ok)"
        >
          <span>Save</span>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
  
<script>
import {
  BCard,
  BRow,
  BCol,
  BModal,
  BFormGroup,
  BFormInput,
  BTab,
  BTabs,
  BFormTextarea,
  BForm,
  BButton,
  BFormFile,
  BImg,
  BFormRadio,
  BFormRadioGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Dropzone from 'dropzone';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import useNightclubMenu from "../useNightclubMenu";
import dayjs from 'dayjs'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    
    BCard,
    BRow,
    BCol,
    BModal,
    BFormGroup,
    BFormInput,
    BTab,
    BTabs,
    BFormTextarea,
    BForm,
    BButton,
    BFormFile,
    BImg,
    BFormRadio,
    BFormRadioGroup,

    vSelect,
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    values: {
      type: Object,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      default: () => false,
    }
  },
  data() {
    return {
      loading: false,
      categoryOptions: [
        { value: '', label: 'Select Category' },
      ],
      statusOptions: [
        { value: 'Activate', label: 'Activate' },
        { value: 'Inactivate', label: 'Inactivate' }
      ],
    }
  },
  methods: {
    async validationForm(callback) {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      if ((this.values.image.size / 1024 / 1024) > 0.7) {
        await this.$refs.image.validate()
        await this.$refs.image.setErrors(['The file size must not exceed the specified size'])
        return;
      }

      const form = {
        image: this.values.image,
        imageUrl: this.values.imageUrl,
        price: this.values.price,
        recommended: this.values.recommended,
        category: this.values.category,
        status: this.values.status,
        english: this.values.english,
        thai: this.values.thai,
        chinese: this.values.chinese,
        name: `${this.form.menu.menu.length + 1}`,
        id: `${this.form.menu.menu.length + 1}`,
        createdTime: dayjs(),
      }

      if (this.isEdit) {
        this.form.menu.menu = this.form.menu.menu.filter(item => item.id !== this.values.id)
        form.name = this.values.name
        form.id = this.values.id
      }
      
      this.form.menu.menu = [...this.form.menu.menu, form];
      callback();
    },
    async handleUploadImage(file) {
      if (!file) return;
      if ((file.size / 1024 / 1024) > 0.7) {
        await this.$refs.image.validate()
        await this.$refs.image.setErrors(['The file size must not exceed the specified size'])
        return;
      }

      const form = new FormData();
      form.append("files", file);

      this.loading = true;
      const { data, status } = await this.uploadImage(form);

      if (status !== 200) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Error upload image",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        this.loading = false;
        return;
      }

      this.loading = false;
      this.values.imageUrl = data[0];
    },
  },
  beforeUpdate() {
    setTimeout(() => {
      const dropzoneElement = document.getElementById('dropzone-modal')
      if (dropzoneElement) {
        try {
          const dropZone = new Dropzone(dropzoneElement, { url: '/', autoProcessQueue: false, disablePreviews: true });
          dropZone.on('addedfile', async (file) => {
            this.$refs.file.setFiles([file])
          })
        } catch (_) {
          // ignored
        }
      }
    }, 100)
  },
  setup() {
    const { uploadImage } = useNightclubMenu();

    return {
      uploadImage
    }
  },
};
</script>
<style scoped>
.h-150px {
  height: 150px;
}
</style>