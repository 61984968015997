import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useNightclubCreate() {
  const toast = useToast()
  const statusOptions = [
    { label: 'Activate', value: 'Activate' },
    { label: 'Inactivate', value: 'Inactivate' },
  ]
  const typeOptions = [
    { label: 'Select Type', value: '' },
    { label: 'ALL', value: 'ALL' },
  ]
  const badgeOptions = [
    { label: 'Select Badge', value: '' },
    { label: 'Recommended', value: 'Recommended' },
    { label: 'New', value: 'New' },
  ]
  const fontTitleOptions = [
    { label: 'Select Font Title', value: '' },
    { label: 'DB Heaven', value: 'db' },
    { label: 'Fabyen Demo', value: 'fabyen' },
    { label: 'Selfie Black', value: 'selfie' },
    { label: 'Montserrat (Bold)', value: 'montserrat-bold' },
    { label: 'Croe', value: 'croe' },
    { label: 'Baskerville (Bold)', value: 'baskerville-bold' },
    { label: 'D-DIN (Bold)', value: 'ddin' },
    { label: 'DIN 1451 Std', value: 'din-1451' },
    { label: 'Gotham', value: 'gotham' },
    { label: 'Gabriel Weiss Friends', value: 'gwf' },
  ]
  const fontBodyOptions = [
    { label: 'Select Font Body', value: '' },
    { label: 'DB Heaven', value: 'db' },
    { label: 'Fabyen Demo', value: 'fabyen' },
    { label: 'DIN 1451 Std', value: 'din-1451' },
    { label: 'Montserrat (Light)', value: 'montserrat-light' },
    { label: 'ITC Avant Garde Gothic (LT Conde)', value: 'itc' },
    { label: 'Baskerville (Italic)', value: 'baskerville-italic' },
    { label: 'DIN 30640 Std', value: 'din-30640' },
    { label: 'Gotham', value: 'gotham' },
  ]
  
  const createNightclub = async (nightclub) => {
    try {
      const response = await store.dispatch('app-nightclub/createNightclub', nightclub);
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error create nightclub',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }
  
  const updateNightclub = async (nightclub) => {
    try {
      const response = await store.dispatch('app-nightclub/updateNightclub', nightclub);
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error update nightclub',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  const getNightclubById = async (id) => {
    try {
      const response = await store.dispatch('app-nightclub/getNightclubById', id);
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching nightclub',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  const uploadImage = async (images) => {
    try {
      const response = await store.dispatch('app-nightclub/uploadImage', images);
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error upload image',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  const checkSlug = async (images) => {
    try {
      const response = await store.dispatch('app-nightclub/checkSlug', images);
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error check slug',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  return {
    toast,
    statusOptions,
    typeOptions,
    badgeOptions,
    fontTitleOptions,
    fontBodyOptions,
    getNightclubById,
    createNightclub,
    updateNightclub,
    uploadImage,
    checkSlug,
  }
}
