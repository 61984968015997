import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'

export default function useNightclubBranch() {
  // Use toast
  const toast = useToast()
  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'hamburger', label: '', class: 'text-center' },
    { key: 'id', label: '#', class: 'text-center' },
    { key: 'name', label: 'Branch name' },
    { key: 'main', label: 'Type' },
    { key: 'status' },
    { key: 'createdTime', label: 'last updated' },
    { key: 'actions', class: 'text-center' },
  ]
  const perPage = ref(10)
  const totalCards = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(false)
  const draggableOptions = ref({
    animation: 400,
  })

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCards.value,
    }
  })

  const refetchData = () => {
    // refListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveCardStatusVariant = status => {
    if (status === 'Activate') return 'success'
    if (status === 'Inactivate') return 'secondary'
    return 'primary'
  }

  return {
    // data
    tableColumns,
    perPage,
    currentPage,
    totalCards,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    draggableOptions,
    
    // UI
    resolveCardStatusVariant,
    refetchData,
  }
}
