<template>
  <div>
    <b-card>
      <b-row>
        <b-col sm="12">
          <h3>MENU STATUS</h3>
        </b-col>
        <b-col sm="12" class="mt-2">
            <b-form-group>
              <label for="recommended">Status <span class="text-red">*</span></label>
              <b-form-radio-group v-model="form.menu.status">
                <b-form-radio value="Enable">
                  Enable
                </b-form-radio>
                <b-form-radio value="Disable">
                  Disable
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>
      </b-row>
    </b-card>

    <b-card>
      <b-row>
        <b-col sm="12">
          <h3>CATEGORY</h3>
        </b-col>
        <b-col sm="12">
          <NightclubCategory :form="form" />
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <b-row>
        <b-col sm="12">
          <h3>MENU</h3>
        </b-col>
        <b-col sm="12">
          <NightclubMenuList :form="form" />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
  
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormRadio,
  BFormRadioGroup,
} from "bootstrap-vue";
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import nightclubStoreModule from '../nightclubStoreModule'
import NightclubMenuList from './NightclubMenuList.vue'
import NightclubCategory from './NightclubCategory.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormRadio,
    BFormRadioGroup,
    NightclubMenuList,
    NightclubCategory,
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const RESTAURANT_APP_STORE_MODULE_NAME = 'app-nightclub'

    // Register module
    if (!store.hasModule(RESTAURANT_APP_STORE_MODULE_NAME)) store.registerModule(RESTAURANT_APP_STORE_MODULE_NAME, nightclubStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(RESTAURANT_APP_STORE_MODULE_NAME)) store.unregisterModule(RESTAURANT_APP_STORE_MODULE_NAME)
    })
  },
};
</script>
