<template>
  <b-row>
    <b-col sm="12" xl="9">
      <b-card>
        <b-row>
          <b-col sm="12">
            <h3>Category</h3>
          </b-col>
          <b-col sm="12" class="mt-1">
            <b-form-checkbox-group
              v-model="form.setting.category"
            >
              <b-form-checkbox value="Daylife">Daylife</b-form-checkbox>
              <b-form-checkbox value="Nightlife">Nightlife</b-form-checkbox>
              <b-form-checkbox value="Stay with Us">Stay with Us</b-form-checkbox>
            </b-form-checkbox-group>
          </b-col>
          <b-col sm="12" class="mt-2">
            <h3>Type</h3>
          </b-col>
          <b-col sm="12" class="mt-1">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="eating"
                rules="required"
              >
                <label for="eating">Eating Style Keywords <span class="text-red">*</span></label>
                <v-select
                  v-model="form.setting.keywords.eating"
                  :options="eatingOption"
                  multiple
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="lifestyle"
                rules="required"
              >
                <label for="lifestyle">Lifestyle Keywords <span class="text-red">*</span></label>
                <v-select
                  v-model="form.setting.keywords.lifestyle"
                  :options="lifestyleOption"
                  multiple
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <b-row>
          <b-col sm="12">
            <h3>SEO</h3>
          </b-col>
          <b-col sm="12">
            <b-row>
              <b-col sm="12">
                <b-form-group>
                  <label for="about-image">OG Image <span class="text-red">*</span></label>
                  <div
                    class="flex flex-col preview-image dashed"
                    :hidden="form.seo.imageUrl !== ''"
                    id="dropzone"
                    :class="loading ? 'pointer-none' : ''"
                  >
                    <div
                      class="flex flex-col items-center"
                      @click="$refs.file.$refs.input.click()"
                    >
                      <feather-icon icon="UploadCloudIcon" size="30" />
                      <div v-if="!loading" class="flex flex-col">
                        <span class="font-18px">Size dimension 800x800 px</span>
                        <span>(Size not exceed 10 MB, PNG, JPG)</span>
                      </div>
                      <span v-else class="font-18px">Loading...</span>
                    </div>
                  </div>
                  <div class="relative" :hidden="form.seo.imageUrl === ''">
                    <b-img :src="form.seo.imageUrl" class="image-full" />
                    <div class="close-button" @click="resetFileUpload">
                      <feather-icon icon="XIcon" size="16" />
                    </div>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    ref="image"
                    name="image"
                    rules="required"
                  >
                    <b-form-file
                      ref="file"
                      :disabled="loading"
                      v-model="form.seo.image"
                      placeholder="Choose file"
                      accept=".jpg, .png"
                      @input="handleUploadImage"
                      class="display-none"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="12">
            <b-tabs pills>
              <b-tab active>
                <template #title>
                  <div class="flex items-center">
                    <span>English</span>
                  </div>
                </template>
                <b-form-group>
                  <label for="content-english-slug">Page URL (Slug)</label>
                  <validation-provider
                    #default="{ errors }"
                    ref="slugenglish"
                    name="slug"
                    rules="required"
                  >
                    <b-input-group
                      prepend="https://akarahospitality.com/nightclubs"
                    >
                      <template #append>
                        <b-input-group-text>
                          <b-spinner small v-if="slugloading" />
                        </b-input-group-text>
                      </template>
                      <b-form-input
                        v-model="form.seo.content.english.url"
                        debounce="200"
                        @change="changeSlug('english')"
                        style="border-right: 0;"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group>
                  <label for="content-english-title">Meta Tag - Title</label>
                  <b-form-input
                    v-model="form.seo.content.english.title"
                    placeholder="Meta Tag - Title"
                    :state="form.seo.content.english.title.length >= 100 ? false : null"
                    :class="
                      form.seo.content.english.title.length >= 100
                        ? 'text-danger'
                        : ''
                    "
                  />
                  <span class="font-12px">{{ form.seo.content.english.title.length }}/100</span>
                </b-form-group>
                <b-form-group>
                  <label for="content-english-description">Meta Tag - Description</label>
                  <b-form-textarea
                    v-model="form.seo.content.english.description"
                    rows="5"
                    placeholder="Meta Tag - Description"
                    :state="
                      form.seo.content.english.description.length >= 200
                        ? false
                        : null
                    "
                    :class="
                      form.seo.content.english.description.length >= 200
                        ? 'text-danger'
                        : ''
                    "
                  />
                  <span class="font-12px"
                    >{{ form.seo.content.english.description.length }}/200</span
                  >
                </b-form-group>
              </b-tab>
              <b-tab>
                <template #title>
                  <div class="flex items-center">
                    <span>Thai</span>
                  </div>
                </template>
                <b-form-group>
                  <label for="content-thai-slug">Page URL (Slug)</label>
                  <validation-provider
                    #default="{ errors }"
                    ref="slugthai"
                  >
                    <b-input-group
                      prepend="https://akarahospitality.com/nightclubs"
                    >
                      <template #append>
                        <b-input-group-text>
                          <b-spinner small v-if="slugloading" />
                        </b-input-group-text>
                      </template>
                      <b-form-input
                        v-model="form.seo.content.thai.url"
                        debounce="200"
                        :disabled="slugloading"
                        @change="changeSlug('thai')"
                        style="border-right: 0;"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group>
                  <label for="content-thai-title">Meta Tag - Title</label>
                  <b-form-input
                    v-model="form.seo.content.thai.title"
                    placeholder="Meta Tag - Title"
                    :state="
                      form.seo.content.thai.title.length >= 100 ? false : null
                    "
                    :class="
                      form.seo.content.thai.title.length >= 100
                        ? 'text-danger'
                        : ''
                    "
                  />
                  <span class="font-12px"
                    >{{ form.seo.content.thai.title.length }}/100</span
                  >
                </b-form-group>
                <b-form-group>
                  <label for="content-thai-description">Meta Tag - Description</label>
                  <b-form-textarea
                    v-model="form.seo.content.thai.description"
                    rows="5"
                    placeholder="Meta Tag - Description"
                    :state="
                      form.seo.content.thai.description.length >= 200
                        ? false
                        : null
                    "
                    :class="
                      form.seo.content.thai.description.length >= 200
                        ? 'text-danger'
                        : ''
                    "
                  />
                  <span class="font-12px">{{ form.seo.content.thai.description.length }}/200</span>
                </b-form-group>
              </b-tab>
              <b-tab>
                <template #title>
                  <div class="flex items-center">
                    <span>Chinese</span>
                  </div>
                </template>
                <b-form-group>
                  <label for="content-chinese-slug">Page URL (Slug)</label>
                  <validation-provider
                    #default="{ errors }"
                    ref="slugchinese"
                  >
                    <b-input-group
                      prepend="https://akarahospitality.com/nightclubs/"
                    >
                      <template #append>
                        <b-input-group-text>
                          <b-spinner small v-if="slugloading" />
                        </b-input-group-text>
                      </template>
                      <b-form-input
                        v-model="form.seo.content.chinese.url"
                        debounce="200"
                        :disabled="slugloading"
                        @change="changeSlug('chinese')"
                        style="border-right: 0;"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group>
                  <label for="content-chinese-title">Meta Tag - Title</label>
                  <b-form-input
                    v-model="form.seo.content.chinese.title"
                    placeholder="Meta Tag - Title"
                    :state="
                      form.seo.content.chinese.title.length >= 100 ? false : null
                    "
                    :class="
                      form.seo.content.chinese.title.length >= 100
                        ? 'text-danger'
                        : ''
                    "
                  />
                  <span class="font-12px"
                    >{{ form.seo.content.chinese.title.length }}/100</span
                  >
                </b-form-group>
                <b-form-group>
                  <label for="content-chinese-description"
                    >Meta Tag - Description</label
                  >
                  <b-form-textarea
                    v-model="form.seo.content.chinese.description"
                    rows="5"
                    placeholder="Meta Tag - Description"
                    :state="
                      form.seo.content.chinese.description.length >= 200
                        ? false
                        : null
                    "
                    :class="
                      form.seo.content.chinese.description.length >= 200
                        ? 'text-danger'
                        : ''
                    "
                  />
                  <span class="font-12px"
                    >{{ form.seo.content.chinese.description.length }}/200</span
                  >
                </b-form-group>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import vSelect from "vue-select";
import Dropzone from 'dropzone'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BSpinner,
  BFormRadioGroup,
  BFormRadio,
  BFormFile,
  BFormCheckbox,
  BFormCheckboxGroup,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BTab,
  BTabs,
  BFormTextarea,
  BInputGroupText,
  BInputGroup,
} from "bootstrap-vue";
import { required, email, regex } from "@validations";
import useNightclubCreate from "./useNightclubCreate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BSpinner,
    BFormRadioGroup,
    BFormRadio,
    BFormCheckbox,
    BFormCheckboxGroup,
    BButton,
    BForm,
    BImg,
    BFormTextarea,
    BFormFile,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupText,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BTab,
    BTabs,

    vSelect,
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      regex,
      email,
      loading: false,
      slugloading: false,
      eatingOption: [
        { label: 'SPANISH', value: 'SPANISH' },
        { label: 'TAPAS BAR', value: 'TAPAS BAR' },
        { label: 'ITALIAN', value: 'ITALIAN' },
        { label: 'YOKITORI', value: 'YOKITORI' },
        { label: 'MEXICO', value: 'MEXICO' },
        { label: 'JAPANESE', value: 'JAPANESE' },
        { label: 'FRENCH', value: 'FRENCH' },
        { label: 'BURGER', value: 'BURGER' },
        { label: 'SEAFOOD', value: 'SEAFOOD' },
        { label: 'PERUVIAN', value: 'PERUVIAN' },
        { label: 'PASTA', value: 'PASTA' },
        { label: 'FAST FOOD', value: 'FAST FOOD' },
      ],
      lifestyleOption: [
        { label: 'LIVE PERFORMANCE', value: 'LIVE PERFORMANCE' },
        { label: 'ROMANTIC', value: 'ROMANTIC' },
        { label: 'LIVE OPERA', value: 'LIVE OPERA' },
        { label: 'DJ', value: 'DJ' },
        { label: 'OUTDOOR', value: 'OUTDOOR' },
        { label: 'CHILL', value: 'CHILL' },
        { label: 'NIGHT CLUB', value: 'NIGHT CLUB' },
        { label: 'BAR', value: 'BAR' },
        { label: 'LIVE BAND', value: 'LIVE BAND' },
        { label: 'FAMILY', value: 'FAMILY' },
      ]
    };
  },
  methods: {
    resetFileUpload() {
      this.form.seo.imageUrl = "";
    },
    async handleUploadImage(file) {
      if (!file) return;
      if ((file.size / 1024 / 1024) > 10) {
        await this.$refs.image.validate()
        await this.$refs.image.setErrors(['The file size must not exceed the specified size'])
        return;
      }

      const form = new FormData();
      form.append("files", file);

      this.loading = true;
      const { data, status } = await this.uploadImage(form);

      if (status !== 200) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Error upload image",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        this.loading = false;
        return;
      }

      this.loading = false;
      this.form.seo.imageUrl = data[0];
    },
    async changeSlug(lang) {
      if (!this.form.seo.content[lang].url) return;

      this.slugloading = true;
      const slug = this.form.seo.content[lang].url;
      const { status } = await this.checkSlug(slug.trim().replaceAll(' ', '-'));

      if (status !== 200) {
        this.form.seo.content[lang].url = ''
        await this.$refs[`slug${lang}`].validate()
        await this.$refs[`slug${lang}`].setErrors(['The slug already created.'])
      }

      this.slugloading = false;
    }
  },
  mounted() {
    const dropzoneElement = document.getElementById('dropzone')
    if (dropzoneElement) {
      const dropZone = new Dropzone(dropzoneElement, { url: '/', autoProcessQueue: false, disablePreviews: true });
      dropZone.on('addedfile', async (file) => {
        this.$refs.file.setFiles([file])
      })
    }
  },
  setup() {
    const {
      toast,
      uploadImage,
      checkSlug,
    } = useNightclubCreate();

    return {
      toast,
      uploadImage,
      checkSlug,
    };
  },
};
</script>
