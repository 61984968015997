<template>
  <div>
    <validation-observer ref="form">
      <b-form>
        <b-tabs pills>
          <b-tab title="Information" active>
            <template #title>
              <div class="flex items-center">
                Information
                <div v-if="showDotInformation" class="dot" />
              </div>
            </template>
            <NightclubInfo :form="form" />
          </b-tab>
          <b-tab title="Menu">
            <template #title>
              <div class="flex items-center">
                Menu
                <div v-if="showDotMenu" class="dot" />
              </div>
            </template>
            <NightclubMenu :form="form" />
          </b-tab>
          <b-tab title="Branch">
            <template #title>
              <div class="flex items-center">
                Branch
              </div>
            </template>
            <NightclubBranch :form="form" />
          </b-tab>
          <b-tab title="Review">
            <template #title>
              <div class="flex items-center">
                Review
                <div v-if="showDotReview" class="dot" />
              </div>
            </template>
            <NightclubReview :form="form" />
          </b-tab>
          <b-tab title="Style">
            <template #title>
              <div class="flex items-center">
                Style
                <div v-if="showDotStyle" class="dot" />
              </div>
            </template>
            <NightclubStyle :form="form" />
          </b-tab>
          <b-tab title="SEO">
            <template #title>
              <div class="flex items-center">
                Setting
                <div v-if="showDotSetting"  class="dot" />
              </div>
            </template>
            <NightclubSetting :form="form" />
          </b-tab>
        </b-tabs>
      </b-form>
    </validation-observer>

    <div class="fixed-footer justify-content-end z-3">
      <b-button variant="outline-secondary" :to="{ name: 'nightclubs' }">
        <span>Cancel</span>
      </b-button>
      <b-button
        class="ml-1"
        variant="success"
        type="submit"
        @click.prevent="validationForm"
      >
        <span>Save</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormRadioGroup,
  BFormRadio,
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BTab,
  BTabs,
  BFormTextarea,
} from "bootstrap-vue";
import { required, email, regex } from "@validations";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import useNightclubCreate from "./useNightclubCreate";
import nightclubStoreModule from "../nightclubStoreModule";
import NightclubInfo from "./NightclubInfo.vue";
import NightclubBranch from "../branch/list/NightclubBranch.vue";
import NightclubReview from "../review/list/NightclubReview.vue";
import NightclubMenu from "../menu/NightclubMenu.vue";
import NightclubStyle from "./NightclubStyle.vue";
import NightclubSetting from "./NightclubSetting.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    NightclubInfo,
    NightclubBranch,
    NightclubReview,
    NightclubMenu,
    NightclubStyle,
    NightclubSetting,

    ValidationProvider,
    ValidationObserver,

    BFormRadioGroup,
    BFormRadio,
    BButton,
    BForm,
    BImg,
    BFormTextarea,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BTab,
    BTabs,

    vSelect,
  },
  data() {
    return {
      required,
      regex,
      email,
      avoidRouteLeave: false,
      isEdit: false,
      form: {
        info: {
          status: "Activate",
          type: '',
          badge: '',
          name: '',
          description: '',
          logo: null,
          logoUrl: '',
          facebook: '',
          instagram: '',
          images: [],
          featureImage: {
            daylife: null,
            daylifeUrl: '',
            nightlife: null,
            nightlifeUrl: '',
          },
          content: {
            thai: {
              section1: '',
              section2: '',
            },
            english: {
              section1: '',
              section2: '',
            },
            chinese: {
              section1: '',
              section2: '',
            },
          },
        },
        menu: {
          status: 'Enable',
          category: [],
          menu: [],
        },
        branch: [],
        review: [],
        style: {
          fontTitle: '',
          fontBody: '',
          color: {
            main: '',
            secondary: '',
          },
          banner: null,
          bannerUrl: '',
          componenta: null,
          componentaUrl: '',
          componentb: null,
          componentbUrl: '',
        },
        seo: {
          image: null,
          imageUrl: "",
          content: {
            english: {
              title: "",
              description: "",
              link: "",
            },
            thai: {
              title: "",
              description: "",
              link: "",
            },
            chinese: {
              title: "",
              description: "",
              link: "",
            },
          },
        },
        setting: {
          category: [],
          keywords: {
            eating: [],
            lifestyle: [],
          },
        }
      },
    };
  },
  methods: {
    async validationForm() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        this.makeToast(false, 'Please complete all of the missing required information')
        return;
      }

      let response = null;
      const nightclub = {
        branch: this.form.branch,
        category: this.form.menu.category,
        information: {
          ...this.form.info,
          logo: new File([''], 'tempfile'),
          temp: this.form.info.images.map(_ => new File([''], 'tempfile')),
          featureImage: {
            ...this.form.info.featureImage,
            daylife: new File([''], 'tempfile'),
            nightlife: new File([''], 'tempfile'),
          }
        },
        menu: this.form.menu.menu.map(item => {
          return {
            ...item,
            image: new File([''], 'tempfile'),
          }
        }),
        review: this.form.review.map(item => {
          return {
            ...item,
            file: new File([''], 'tempfile'),
          }
        }),
        setting: {
          ...this.form.setting,
          menuStatus: this.form.menu.status,
          seo: {
            ...this.form.seo,
            image: new File([''], 'tempfile'),
          }
        },
        status: this.form.info.status,
        style: {
          ...this.form.style,
          banner: new File([''], 'tempfile'),
          componenta: new File([''], 'tempfile'),
          componentb: new File([''], 'tempfile'),
        }
      };
      
      if (this.isEdit) {
        response = await this.updateNightclub({ id: this.$router.currentRoute.params.id, ...nightclub });
      } else {
        response = await this.createNightclub(nightclub);
      }

      if (response.status !== 200) {
        this.makeToast(false);
        return;
      }

      this.avoidRouteLeave = true;
      if (this.isEdit) {
        this.makeToast(true); 
      } else {
        this.$router
          .push({ name: 'nightclubs' })
          .then(() => {
            this.makeToast(true)
          })
      }
    },
    makeToast(success, message) {
      this.$bvToast.toast(
        <div class="d-flex">
          <feather-icon
            class={success ? "text-success" : "text-danger"}
            icon={success ? "CheckCircleIcon" : "XCircleIcon"}
            size="24"
          />
          <div class="d-flex flex-column">
            <h4 class={success ? "ml-1 text-success" : "ml-1 text-danger"}>
              {success ? "Save succeeded!" : "Save failed!"}
            </h4>
            <div class="ml-1 font-14px">
              {message ? 'Please complete all of the missing required information' : ''}
              {success && !message
                ? `Nightclub has been saved.`
                : "Can not saved nightclub."
              }
            </div>
          </div>
        </div>,
        {
          headerClass: "d-none",
          solid: true,
        }
      );
    },
  },
  computed: {
    showDotInformation() {
      return !(
        this.form.info.content.english.section1 &&
        this.form.info.status &&
        this.form.info.type &&
        this.form.info.name &&
        this.form.info.description &&
        this.form.info.logoUrl &&
        this.form.info.facebook &&
        this.form.info.instagram &&
        this.form.info.featureImage.daylifeUrl &&
        this.form.info.featureImage.nightlifeUrl &&
        this.form.info.images.length > 0
      )
    },
    showDotMenu() {
      return !(
        this.form.menu.menu.length > 0 &&
        this.form.menu.category.length > 0
      )
    },
    showDotBranch() {
      return !(
        this.form.branch.length > 0
      )
    },
    showDotReview() {
      return !(
        this.form.review.length > 0
      )
    },
    showDotStyle() {
      return !(
        this.form.style.fontTitle &&
        this.form.style.fontBody &&
        this.form.style.color &&
        this.form.style.color &&
        this.form.style.bannerUrl
      )
    },
    showDotSetting() {
      return !(
        this.form.seo.imageUrl &&
        this.form.seo.content.english.url &&
        this.form.seo.content.english.title &&
        this.form.setting.category.length > 0 &&
        this.form.setting.keywords.eating.length > 0 &&
        this.form.setting.keywords.lifestyle.length > 0
      )
    }
  },
  beforeRouteLeave(_, from, next) {
    if (this.avoidRouteLeave) {
      next()
      return
    }

    this.$swal({
      title: "Are you sure to leave?",
      text: "Do you really want to leave? you have unsaved changes!",
      icon: "warning",
      iconColor: "#FF9F43",
      showCancelButton: true,
      confirmButtonText: "Leave",
      customClass: {
        htmlContainer: "my-50",
        actions: "flex-row-reverse my-1",
        confirmButton: "btn btn-danger px-5",
        cancelButton: "btn btn-outline-secondary px-5 mr-1",
      },
      buttonsStyling: false,
    }).then(async (result) => {
      if (!result.value) {
        next(false);
      } else {
        next()
      }
    });
  },
  async created() {
    try {
      const id = this.$router.currentRoute.params.id;
      if (!id) return;
      const { data } = await this.getNightclubById(id);
      this.form.info = {
        ...data.information,
        logo: new File([''], 'tempfile'),
        temp: data.information.images.map(_ => new File([''], 'tempfile')),
        featureImage: {
          ...data.information.featureImage,
          daylife: new File([''], 'tempfile'),
          nightlife: new File([''], 'tempfile'),
        }
      }
      this.form.branch = data.branch || [];
      this.form.review = data.review;
      this.form.style = {
        ...data.style,
        banner: new File([''], 'tempfile'),
        componenta: new File([''], 'tempfile'),
        componentb: new File([''], 'tempfile'),
      };
      this.form.seo = {
        ...data.setting.seo,
        image: new File([''], 'tempfile'),
      }
      this.form.setting.category = data.setting.category;
      this.form.setting.keywords = data.setting.keywords;
      this.form.menu.category = data.category;
      this.form.menu.menu = data.menu;
      this.form.menu.status = data.setting.menuStatus;
      this.isEdit = true;
    } catch (error) {
      this.toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching nightclub details.',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  },
  setup() {
    const RESTAURANT_APP_STORE_MODULE_NAME = "app-nightclub";

    // Register module
    if (!store.hasModule(RESTAURANT_APP_STORE_MODULE_NAME))
      store.registerModule(
        RESTAURANT_APP_STORE_MODULE_NAME,
        nightclubStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(RESTAURANT_APP_STORE_MODULE_NAME))
        store.unregisterModule(RESTAURANT_APP_STORE_MODULE_NAME);
    });

    const {
      toast,
      getNightclubById,
      createNightclub,
      updateNightclub,
      uploadImage,
    } = useNightclubCreate();

    return {
      toast,
      getNightclubById,
      createNightclub,
      updateNightclub,
      uploadImage,
    };
  },
};
</script>

<style scoped>
.pointer-none {
  cursor: not-allowed;
  pointer-events: none;
}
.z-3 {
  z-index: 3;
}
</style>
