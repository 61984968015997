import Sortable from 'sortablejs';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const draggabletable = {
  bind(el, binding, vnode) {
    const table = el;
    table._draggable = Sortable.create(table.querySelector('tbody'), {
      ...binding.value,
      onEnd: async evt => {
        const context = vnode.context;
        const oldPosition = evt.oldIndex + 1;
        const newPosition = evt.newIndex + 1;
        try {
          context.isBusy = true
          await context.swapPosition(oldPosition, newPosition);
          setTimeout(context.isBusy = false, 100);
          await context.refetchData();
        } catch (error) {
          console.log(error)
          context.changePositionToast()
        }
      }
    });
  }
};

const draggabletags = {
  bind(el, binding, vnode) {
    const ul = el.querySelector('ul');
    ul._draggable = Sortable.create(ul, {
      ...binding.value,
      onEnd: evt => {
        console.log(evt);
        const data = vnode.context;
        const targetItem = data.tags.splice(evt.oldIndex, 1)[0];
        data.tags.splice(evt.newIndex, 0, targetItem);
      }
    });
  }
};

export { draggabletable, draggabletags };
