import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getNightclubs(_, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/lineClubList`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getNightclubById(_, id) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/lineClubById', { params: { id } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    checkSlug(_, search) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/slugCheck', { params: { search } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createNightclub(_, nightclub) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/lineClubCreate', nightclub)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateNightclub(_, nightclub) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/lineClubUpdate', nightclub)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteNightclub(_, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/lineClubDelete`, { id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    swapPosition(_, { oldPosition, newPosition}) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/movePosition/lineClub', { old: oldPosition, new: newPosition })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadImage(_, images) {
      return new Promise((resolve, reject) => {
        axios
          .post('/uploader', images, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
