<template>
  <b-modal
    id="modal-category-create"
    centered
  >
    <template #modal-header="{ close }">
      <div class="flex w-full justify-between items-center py-1">
        <h3 class="mb-0">{{ isEdit ? 'Edit Category' : 'Add Category' }}</h3>
        <feather-icon
          class="cursor-pointer"
          icon="XIcon"
          size="20"
          @click="close"
        />
      </div>
    </template>

    <validation-observer ref="form">
      <b-form>
        <b-row>
          <b-col sm="12">
            <b-tabs pills nav-class="tab-grow">
              <b-tab title="English" active>
                <b-form-group>
                  <label for="category-name">Category Name <span class="text-red">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    name="category name"
                    rules="required"
                  >
                    <b-form-input
                      v-model="values.english.categoryName"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Category Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group>
                  <label for="category-description">Description</label>
                  <b-form-textarea
                    v-model="values.english.categoryDescription"
                    rows="5"
                    placeholder="Description"
                  />
                </b-form-group>
              </b-tab>
              <b-tab title="Thai">
                <b-form-group>
                  <label for="category-name">Category Name</label>
                  <b-form-input
                    v-model="values.thai.categoryName"
                    placeholder="Category Name"
                  />
                </b-form-group>
                <b-form-group>
                  <label for="category-description">Description</label>
                  <b-form-textarea
                    v-model="values.thai.categoryDescription"
                    rows="5"
                    placeholder="Description"
                  />
                </b-form-group>
              </b-tab>
              <b-tab title="Chinese">
                <b-form-group>
                  <label for="category-name">Category Name</label>
                  <b-form-input
                    v-model="values.chinese.categoryName"
                    placeholder="Category Name"
                  />
                </b-form-group>
                <b-form-group>
                  <label for="category-description">Description</label>
                  <b-form-textarea
                    v-model="values.chinese.categoryDescription"
                    rows="5"
                    placeholder="Description"
                  />
                </b-form-group>
              </b-tab>
            </b-tabs>
          </b-col>
          <b-col sm="12">
            <b-form-group>
              <label for="status">Status <span class="text-red">*</span></label>
              <validation-provider
                #default="{ errors }"
                name="status"
                rules="required"
              >
                <v-select
                  v-model="values.status"
                  :state="errors.length > 0 ? false:null"
                  :options="statusOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="status"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <template #modal-footer="{ cancel, ok }">
      <div class="flex w-full">
        <b-button
          class="flex-grow"
          variant="outline-secondary"
          @click="cancel"
        >
          <span>Cancel</span>
        </b-button>
        <b-button
          class="flex-grow ml-1"
          variant="success"
          type="submit"
          @click="validationForm(ok)"
        >
          <span>Save</span>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
  
<script>
import {
  BCard,
  BRow,
  BCol,
  BModal,
  BFormGroup,
  BFormInput,
  BTab,
  BTabs,
  BFormTextarea,
  BForm,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import nightclubStoreModule from '../../nightclubStoreModule'
import dayjs from 'dayjs'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    
    BCard,
    BRow,
    BCol,
    BModal,
    BFormGroup,
    BFormInput,
    BTab,
    BTabs,
    BFormTextarea,
    BForm,
    BButton,

    vSelect,
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    values: {
      type: Object,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      default: () => {}
    }
  },
  data() {
    return {
      statusOptions: [
        { value: 'Activate', label: 'Activate' },
        { value: 'Inactivate', label: 'Inactivate' }
      ],
    }
  },
  methods: {
    async validationForm(callback) {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return false;

      const form = {
        english: this.values.english,
        thai: this.values.thai,
        chinese: this.values.chinese,
        status: this.values.status,
        name: `${this.form.menu.category.length + 1}`,
        id: `${this.form.menu.category.length + 1}`,
        createdTime: dayjs(),
      }

      if (this.isEdit) {
        this.form.menu.category = this.form.menu.category.filter(item => item.id !== this.values.id)
        form.name = this.values.name
        form.id = this.values.id
      }

      this.form.menu.category = [...this.form.menu.category, form];
      callback();
    },
  },
  setup() {
    const RESTAURANT_APP_STORE_MODULE_NAME = 'app-nightclub'

    // Register module
    if (!store.hasModule(RESTAURANT_APP_STORE_MODULE_NAME)) store.registerModule(RESTAURANT_APP_STORE_MODULE_NAME, nightclubStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(RESTAURANT_APP_STORE_MODULE_NAME)) store.unregisterModule(RESTAURANT_APP_STORE_MODULE_NAME)
    })
  },
};
</script>
